import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import spinnergif from '../../images/installing-spark/html/html-spinner.gif';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`This the final Part of a 3-Part series. You can also check out
`}<a parentName="p" {...{
          "href": "/installing-spark/html-environment-setup"
        }}>{`Part 1 - Setting Up Your Environment`}</a>{` and
`}<a parentName="p" {...{
          "href": "/installing-spark/html-installation"
        }}>{`Part 2 - Installation`}</a>{`.`}</p>
    </blockquote>
    <h1>{`Part 3: Adding Spark Components in an HTML Project`}</h1>
    <p>{`This guide will cover how to add components for HTML, CSS and JavaScript.`}</p>
    <p>{`Our `}<a href="https://github.com/sparkdesignsystem/spark-starter-html/" target="_blank">{`Starter Github Repositories`}</a>{` are also available for reference:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/kitchen-sink"
        }}>{`'kitchen-sink' branch`}</a>{` (Spark Installed with component examples.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/without-spark"
        }}>{`'without-spark' branch`}</a>{` (all of the prerequisites before Spark Installation.)`}</li>
    </ul>
    <p>{`For instructions on setting up Spark in other environments, check out the
guides for `}<a parentName="p" {...{
        "href": "/installing-spark/angular"
      }}>{`Angular`}</a>{` and `}<a parentName="p" {...{
        "href": "/installing-spark/react"
      }}>{`React`}</a>{`.`}</p>
    <h2>{`Adding Your First Spark Component`}</h2>
    <p>{`Adding a Spinner Button can confirm you've installed Spark's Styles and JavaScript correctly.`}</p>
    <p>{`This is just one example to implement functionality and get your project started.`}</p>
    <ol>
      <li parentName="ol">{`Add Spark Button code into your `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` and include an `}<inlineCode parentName="li">{`onclick`}</inlineCode>{` and a `}<inlineCode parentName="li">{`data-id`}</inlineCode>{`.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <button
    class="sprk-c-Button"
    data-id="button-spinner-1"
    onclick="startSpinner()"
  >
    Start Spinning
  </button>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: Inside the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag of `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`, the line `}<inlineCode parentName="p">{`<script src="main.js"></script>`}</inlineCode>{` should be after
any other HTML elements.`}</p>
    </blockquote>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` file, import `}<inlineCode parentName="li">{`setSpinning`}</inlineCode>{` from Spark and create a `}<inlineCode parentName="li">{`startSpinner`}</inlineCode>{` function (this is the function that will run `}<inlineCode parentName="li">{`onclick`}</inlineCode>{`).`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { setSpinning } from '@sparkdesignsystem/spark';

window.startSpinner = () => {
  const spinningButton = document.querySelector('[data-id="button-spinner-1"]');
  setSpinning(spinningButton, {});
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Build your app with `}<inlineCode parentName="li">{`npm run build`}</inlineCode>{`, and you should find a Spark Button that loads a Spinner on click.`}</li>
    </ol>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={spinnergif} alt="A Spark Button Spinner component." />
    </div>
    <blockquote>
      <p parentName="blockquote">{`Learn more about Buttons and Spinner functionality in the `}<a parentName="p" {...{
          "href": "https://html.sparkdesignsystem.com/?path=/docs/components-button--primary"
        }}>{`Button Storybook documentation`}</a>{`.`}</p>
    </blockquote>
    <h2>{`Finding the code to other Spark Components`}</h2>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://html.sparkdesignsystem.com/"
        }}>{`Spark HTML Storybook`}</a></li>
      <li parentName="ol">{`Find the Component you need in the "Components" section.`}</li>
      <li parentName="ol">{`Navigate to the `}<em parentName="li">{`Docs`}</em>{` tab. It's typically at the top of screen next to `}<em parentName="li">{`Canvas`}</em>{`.`}</li>
      <li parentName="ol">{`Navigate down the page until you find your component variant.`}</li>
      <li parentName="ol">{`The `}<em parentName="li">{`Show code`}</em>{` button will toggle a code sample.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`These code samples represent the `}<em parentName="p">{`rendered state`}</em>{`
after Spark's JavaScript functions run. Some functionality requires additional engineering.`}</p>
    </blockquote>
    <p>{`The `}<em parentName="p">{`Docs`}</em>{` section of each page will give implementation details for each component.`}</p>
    <h2>{`Additional Topics`}</h2>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-icons"
        }}>{`Icon Installation Guide`}</a>{` for
importing the Spark SVG icon set.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-fonts"
        }}>{`Font Installation Guide`}</a>{` for
instructions on using the Rocket Sans font.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      